<template>
	<div>
		<s-crud
			title="Grupos de Empaque"
            ref="gridgroupperson"
			:config="config"
			:filter="filter"
			add
            edit
            add-no-function
			@save="save($event)"
            @add="add()"
            @edit="edit()"			 	
			search-input
            @rowSelected="rowSelected($event)"
		>
            <template v-slot:filter>
                <v-container>
                    <v-row justify="center">
                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-select-definition
                            label="Turno"
                                v-model="filter.TypeTurn"
                                :def="1278">                            
                            </s-select-definition>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:PggStatus="{ row }">
				<v-icon :color="row.PggStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
        	</template>

            <template v-slot:numberPerson="{ row }">
				<v-chip x-small=""  :color="row.numberPerson > 0 ? 'warning': 'error'" >
					{{ row.numberPerson }}
				</v-chip>
        	</template>

		</s-crud> 
        <v-dialog max-width="auto" v-model="openDialog" persistent>
            <group-detail @save="save()" @close="close()" :selectGroup="selectGroup" v-model="PggID"></group-detail>
        </v-dialog>
	</div>
</template>



<script>
	import _PackingGroup from "@/services/FrozenProduction/FrzPackingGroupService.js"; 
    import GroupDetail from "./FrzPackingGroupDetail.vue"

	export default {
        components: { GroupDetail },
		data: () =>({

            config: {
                model: {
                    PggID: "ID",
                    PrsDocumentNumber: "string",
                    PggStatus:"",
                    numberPerson:""
                },
                service: _PackingGroup,
                headers: [
                    {text: "ID", value: "PggID"},
                    {text: "Nombre Grupo", value: "PggNameGroup"},
                    {text: "Turno", value:"TypeTurnName"},                    
                    {text: "N° Integrantes", value: "numberPerson"},
                    {text: "Zona de trabajo", value: "TypeZoneWorkName"},
                    {text: "Estado", value: "PggStatus"},
                ]
            },
            filter: { PdgNameGroup: 0},

            openDialog: false,
            PggID: 0,
            selectGroup: []
		}),

		methods: {
            add()
            {
                this.PggID = '';
                this.selectGroup = [];
                this.openDialog = true;
            },
            edit(){                             
                this.openDialog = true;
            },   
            close(){                
                this.openDialog = false;
                this.PggID = '';
                this.selectGroup = [];
                this.$refs.gridgroupperson.refresh();
            },
            save(){
                this.$refs.gridgroupperson.refresh();
            },         
            rowSelected(item){
                this.selectGroup = item;
                this.PggID = this.selectGroup.length > 0 ? this.selectGroup[0].PggID : 0;
            },
            
        }
	}
</script>